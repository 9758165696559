import type { FC } from 'react'

import React from 'react'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { useRouter } from 'next/router'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Box, Button, TextField } from '@mui/material'

import logger from 'src/frontend/utils/console'
import { fetcher } from 'src/common/utils/api-utils'

const log = logger('form:login')

interface IFormInput {
  email: string
  password: string
}

const LoginForm: FC = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const router = useRouter()

  const {
    formState: { errors },
    register,
    handleSubmit
  } = useForm<IFormInput>({
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const { email, password } = data
    setIsLoading(true)

    try {
      await fetcher('/api/auth', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          password
        })
      })

      toast.success('You have been logged in.')
      if (router.query.page) {
        return router.push(String(router.query.page))
      } else {
        router.push('/overview')
      }
    } catch (err) {
      log.error({ err }, 'login failed with ERROR')
      toast.error('Incorrect email or password.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} {...props}>
      <TextField
        autoFocus
        fullWidth
        error={!!errors.email}
        helperText={errors?.email?.message}
        label='Email Address'
        margin='normal'
        type='text'
        {...register('email', {
          required: 'Email Address is required',
          maxLength: {
            value: 64,
            message: 'Email Address cannot be longer than 64 characters'
          }
        })}
      />
      <TextField
        fullWidth
        error={!!errors.password}
        helperText={errors?.password?.message}
        label='Password'
        margin='normal'
        type='password'
        {...register('password', {
          required: 'Password is required',
          maxLength: {
            value: 64,
            message: 'Password cannot be longer than 64 characters'
          }
        })}
      />
      <Box sx={{ mt: 2 }}>
        <Button
          disabled={isLoading}
          fullWidth
          size='large'
          type='submit'
          variant='contained'
        >
          Log In
        </Button>
      </Box>
    </form>
  )
}

export default LoginForm

import type { NextPage, GetStaticPropsContext } from 'next'
import type { NextPageWithLayout } from '@/types/app'

import React from 'react'
import Head from 'next/head'
import NextLink from 'next/link'
import NextImage from 'next/image'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography
} from '@mui/material'

import Logo from '@/components/logo'
import LoginForm from '@/components/authentication/login-form'
import FocusLayout from '@/layouts/focus-layout/layout'
import { APP_DISPLAY_NAME, INST_DISPLAY_NAME } from '@/configs/constants'
import { sessionOptions } from '@/backend/session'
import { withIronSessionSsr } from 'iron-session/next'

const LoginPage: NextPageWithLayout = () => {
  const router = useRouter()

  // const { isAuthenticated, isValidating } = useCurrentUser()

  // useEffect(() => {
  //   if (isValidating) {
  //     return
  //   }

  //   if (isAuthenticated) {
  //     router.replace('/overview')
  //   }
  // }, [isAuthenticated, router, isValidating])

  return (
    <>
      <Head>
        <title>Login | {APP_DISPLAY_NAME}</title>
      </Head>

      <Box
        component='main'
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Card elevation={16} sx={{ p: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <NextLink href='/' passHref>
              <a>
                <Logo
                  sx={{
                    height: 40,
                    width: 40
                  }}
                />
              </a>
            </NextLink>
            <Typography variant='h4'>{APP_DISPLAY_NAME}</Typography>
            <Typography color='textSecondary' sx={{ mt: 2 }} variant='body2'>
              {INST_DISPLAY_NAME}
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              mt: 3
            }}
          >
            <LoginForm />
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ mt: 1 }}>
            <NextLink passHref href='/password-recovery'>
              <Link color='textSecondary' variant='body2'>
                Forgot password
              </Link>
            </NextLink>
          </Box>
        </Card>
      </Box>
    </>
  )
}

LoginPage.getLayout = (page) => (
  <FocusLayout showInstLogo={false}>{page}</FocusLayout>
)

export default LoginPage

export const getServerSideProps = withIronSessionSsr(async function ({ req }) {
  const user = req.session?.user

  if (user) {
    return {
      redirect: {
        destination: '/overview',
        permanent: false
      }
    }
  }

  return {
    props: {}
  }
}, sessionOptions)
